function handleClick(btnID,resp){
	$(`#apiResponse-${btnID}`).html(JSON.stringify(resp, null, 2));
	hljs.highlightBlock(document.getElementById(`apiResponse-${btnID}`));
		if ($('.response-content').eq(btnID)
				.children('button').length < 1) {
				$(`#apiResponse-${btnID}`).prepend('<button class="btn btn-cta-secondary response-copy-button">Copy</button>');
				$(`#apiResponse-${btnID}`).children('button')
					.click(function () {
						$('.clipboard-input').show();
						let text = this.parentElement.textContent.trim();
						text = text.substring(4);
						$('.clipboard-input').empty();
						$('.clipboard-input').text(text);
						$('.clipboard-input').select();
						document.execCommand('copy');
						$('.clipboard-input').hide();
					});
		}
	$('.response-content').eq(btnID).slideDown('slow');
		
}
